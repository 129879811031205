import React from 'react';
import { createRoot } from 'react-dom/client';
import BlueskyUpdates from './components/BlueskyUpdates.js';
import SignupForm from './components/SignupForm.js';

window.siteTranslations = {};
if (window.site_translations) {
  window.siteTranslations = window.site_translations;
}

// Initialize React components when the DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  const updatesContainer = document.getElementById('updates');
  if (updatesContainer) {
    const root = createRoot(updatesContainer);
    root.render(React.createElement(BlueskyUpdates));
  }

  const volunteerContainer = document.getElementById('volunteer');
  if (volunteerContainer) {
    const root = createRoot(volunteerContainer);
    root.render(React.createElement(SignupForm));
  }
});

